.rvNS{
  a.extLink{
    text-decoration:none;
  }

  .hideWidget{
    display: none;
  }
  .displayWidget{
    display: block;
  }

}
