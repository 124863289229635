* {
  box-sizing: border-box;
}

body,
html {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  margin: 0;
  font-size: 18px;

}


h1{
  color: #ce5978;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.1;   

}

h2{
  color: rgba(0,0,0,0.7);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.1;    
}

h2 small{
  color: rgba(0,0,0,0.5);
 
}

h3{
  color: rgba(0,0,0,0.6);
  font-size: 18px;
  font-weight: 300;
  line-height: 1.1;    
}


.App {
  text-align: justify;
  padding-top: 30px;
  padding-bottom: 50px;
}


.App.grey {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: rgba(0,0,0,0.1);
}

.box {
  height: 100%;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 5px;
  background-color: #fff;
  padding: 18px;
}

.appBar{
  margin-bottom: 10px;
}

.appBar.MuiAppBar-colorPrimary{
  background-color: #fff;
  color:rgba(0,0,0,0.8);
}

.appBar.MuiAppBar-colorPrimary h2{
  color:rgba(0,0,0,0.8);

}


label {
  color: rgba(0,0,0,0.6);
  font-weight: 500;
  letter-spacing: 0.025em;
}


input,
.StripeElement {
  display: block;
  margin: 5px 0 10px 0;
  width: 100%;
  padding: 20px 24px;
  font-size: 1em;
  font-family: monospace;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}


input::placeholder {
  color: #aab7c4;
}

.countrySelect .MuiInput-input{
  font-family: "Source Code Pro", monospace;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}


.mandate{
  line-height: 1.3;
  font-weight: 300; 
  letter-spacing: 0.2em;   
}


.danger{
  color: red;
  font-size: 0.8em;
}


.overlay{
  position: fixed;
  display:block;
  background-color: rgba(0,0,0,0.8);
  color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}